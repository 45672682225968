import React from 'react'

function Announcement() {
  return (
    <div className='announcement'>
        <p className='ann-title'>Announcements - </p>
        <marquee>
            <span className='marquee1'>Admission for all the classes are open.</span>
        </marquee>
    </div>
  )
}

export default Announcement
